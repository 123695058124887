/* istanbul ignore file */

import * as zoid from 'zoid/dist/zoid';

window.originalOpen = window.open;

window.monduCheckout = {
  sdkProps: {},
  init: function (props) {
    this.sdkProps = {
      ...props
    };
  },
  destroy: function () {
    zoid.destroyComponents();
  },
  create: zoid.create({
    tag: 'mondu-checkout-widget',
    url: `${process.env.APP_URL}/index.html`,
    dimensions: {
      width: '100%',
      height: '100%'
    },
    prerenderTemplate: loadingTemplate,
    props: {
      token: {
        type: 'string',
        required: true
      },
      onClose: {
        type: 'function',
        required: false
      },
      onCancel: {
        type: 'function',
        required: false
      },
      onSuccess: {
        type: 'function',
        required: false
      },
      onError: {
        type: 'function',
        required: false
      }
    }
  }),
  render(props) {
    if (!props.token) {
      throw new Error('A token should be provided to the widget.');
    }

    this.init(props);

    const onClose = async (data) => {
      if (this.sdkProps?.onClose) {
        await this.sdkProps.onClose(data);
        this.destroy();
      } else {
        this.destroy();
      }
    };

    return this.create({ ...this.sdkProps, onClose }).render('#mondu-checkout-widget');
  }
};

window.open = window.originalOpen;

window.seon = {
  config: {
    host: 'seondf.com',
    session_id: '[session_id]',
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
    max_proxy_delay: 1000,
    onSuccess: function (message) {
      console.log('success', message);
    },
    onError: function (message) {
      console.log('error', message);
    }
  },
  getBase64Session: function (data) {
    if (data) {
      console.log('Session payload', data);
    } else {
      console.log('Failed to retrieve session data.');
    }
  }
};

function loadingTemplate({ doc, props }) {
  const html = doc.createElement('html');
  html.innerHTML = `
  <html>
    <head>
      <style>
        body {
          overflow: hidden;
        }

        .loader-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }

        .loader {
          width: 40px;
          height: 40px;
          border: 5px solid #b3b3b3;
          border-radius: 50%;
          border-top: 5px solid #46086d;
          -webkit-animation: spin 2s linear infinite;  /* Safari */
          animation: spin 2s linear infinite;
        }
        
        /* Safari */
        @-webkit-keyframes spin {
          0% { -webkit-transform: rotate(0deg); }
          100% { -webkit-transform: rotate(360deg); }
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      </style>
    </head>
    <body>
      <div class="loader-container">
        <div class="loader">
      </div>
    </body>
  </html>`;

  return html;
}
